class ZIDXAccountBillingHistory implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountBillingHistoryContainer";
    }
    getMatchURL(){
        return "/account/payment/billing-history";
    }
    render(){
        // console.log("billing history");
        let listView=new ZIDXAccountListView();
        listView.render();
    }
}